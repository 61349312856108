    import React from 'react';
    import  RRCarosel  from './carosel/Carosel';
    import  Submenu  from './submenu/Submenu';
    import Programm  from './programm/Programm';
    import Dates from './dates/Dates';
    import News from './news/News';
    import  HTBARR from './htbrr/HTBARR';
    import Contact from './contact/Contact';





    export default class Mainpage extends React.Component {
      render() {
        return (
           <div className="Mainpage Marketing " id="Home">
             <RRCarosel/>
             <div className='container marketing'>
                  <Submenu/>
                  <News/>

                  <Dates/>
                  <hr className='featurette-divider' / >
                  <Programm/>
                  <hr className='featurette-divider' />
                  <HTBARR/>
                  <hr className='featurette-divider' />
                  <Contact/>
                  <hr className='featurette-divider' />
             </div>
          </div>
        );
      }
    }
    