    import React, { Component } from 'react';
    import { Navbar, Nav } from 'react-bootstrap';
    import  Impress  from '../impress/Impress';
   
    
    export default class Menu extends Component {

      constructor (){
        super() 
        this.state =  {
          showHide: false
        }
      }

      handleModalShowHide(){
        this.setState ({showHide : true})
      }
  
      render() {
        return (
         <div>
         <Navbar bg="dark" variant="dark" fixed="bottom" expand="sm">
            <Navbar.Brand href="../#Home">RR-142 Winnenden</Navbar.Brand>
            <a target="blank" href="http://haus-elim.de/" >sponsored by <img src='/img/elim.png' alt="elim"/></a>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                
                 <Nav.Link href="/../" >Home</Nav.Link>
                 <Nav.Link onClick={() => this.handleModalShowHide()} >Impressum</Nav.Link>
                 <Nav.Link href="/../#Kontakt">Kontakt</Nav.Link>
             </Nav>
             </Navbar.Collapse>
        </Navbar>
        <Impress showHide={this.state.showHide}/>
        </div>

        );
      };
    };
    