import React, { PureComponent } from 'react';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import  L  from 'leaflet';
import  'leaflet/dist/leaflet.css';

//import { Test } from './Places.styles';

class Places extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentWillMount = () => {
    console.log('Places will mount');
  }

  componentDidMount = () => {
    console.log('Places mounted');
  }

  componentWillReceiveProps = (nextProps) => {
    console.log('Places will receive props', nextProps);
  }

  componentWillUpdate = (nextProps, nextState) => {
    console.log('Places will update', nextProps, nextState);
  }

  componentDidUpdate = () => {
    console.log('Places did update');
  }

  componentWillUnmount = () => {
    console.log('Places will unmount');
  }

  render () {

    const customMarker = L.icon({ 
      iconUrl: './img/Icon_RR.png', 
      shadowUrl: './img/Icon_RR.png',
			iconSize:     [50, 45],
			//shadowSize:   [50, 64],
			iconAnchor:   [25, 22],
			//shadowAnchor: [4, 62],
			popupAnchor:  [0, 0]
    
    
    
    })


    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div className="PlacesWrapper">
        <Map center={[48.87288, 9.405488]} zoom={13} scrollWheelZoom={false} style={{height : '400px'}} >
  <TileLayer
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker  icon={customMarker} position={[48.87288, 9.405488]}>
    <Popup>
    <b>Unser Treffpunkt von Oktober bis Ostern</b><br/>
     Hope Kirche <br/> 
     Schorndorfer Straße 43-45 <br/>
     71364 Winnenden
    </Popup>
  </Marker>
  <Marker  icon={customMarker} position={[48.884974, 9.42053]}>
    <Popup>
    <b>Unser Treffpunkt von Ostern bis Oktober</b><br/>
    Rangerwiese<br/>
     Am Ende der Wiesenstraße in Winnenden-Hertmannsweiler 
    </Popup>
  </Marker>

</Map>
      </div>
    );
  }
}

Places.propTypes = {
  // bla: PropTypes.string,
};

Places.defaultProps = {
  // bla: 'test',
};

export default Places;
