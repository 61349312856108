import React, { PureComponent } from 'react';
//import PropTypes from 'prop-types';
import {Image, Accordion, Card, AccordionCollapse} from 'react-bootstrap'

//import { Test } from './RrAccordion.styles';

class RrAccordion extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  madress(params) {
  var part1 = params
  var part2 = Math.pow(2,6);
  var part3 = "mailto:"
  var part4 = "rr142.de";
  var part5 = part3 + part1 + String.fromCharCode(part2) + part4;
  return(part5);
  
}
  
  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }


    if (this.props.WithImages === 'true') {
  
      return (
<div className="RrAccordionWrapper">
                   <Accordion>
  
                   {this.props.data.map((AccordionTab, i) => {
              return (
              
                <Card key= {i}>
                  
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey={AccordionTab.Title}>
                    {AccordionTab.Title} {AccordionTab.SubTitle && <small>{AccordionTab.SubTitle}</small>}
                    </Accordion.Toggle>
                  
                  <AccordionCollapse eventKey={AccordionTab.Title}>
                    <Card.Body>
                      <div>
                      {AccordionTab.Image && <Image className="ContactImage" src={AccordionTab.Image} roundedCircle  />} 
                      {AccordionTab.Vorname} {AccordionTab.Nachname} <br/>
                      {AccordionTab.Telefon}
                     <a href={this.madress(AccordionTab.Title)}>Mail</a>
                     </div>
                    </Card.Body>
  
                  </AccordionCollapse>
                </Card>
              
              );
            })}  
          </Accordion>
        </div>

      )
    } else {
      return (
        <div className="RrAccordionWrapper">
                   <Accordion>
  
                   {this.props.data.map((AccordionTab, i) => {
              return (
              
                <Card key= {i}>
                  
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey={AccordionTab.Title}>
                  {AccordionTab.Title} {AccordionTab.SubTitle && <small>{AccordionTab.SubTitle}</small>}
                    </Accordion.Toggle>
                  
                  <AccordionCollapse eventKey={AccordionTab.Title}>
                    <Card.Body>
                      {AccordionTab.Content}
                    </Card.Body>
  
                  </AccordionCollapse>
                </Card>
              
              );
            })}  
          </Accordion>
        </div>
      );
    }
    }
    
}


RrAccordion.propTypes = {
  // bla: PropTypes.string,
};

RrAccordion.defaultProps = {
  // bla: 'test',
};

export default RrAccordion;
