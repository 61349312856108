import React, { Component } from 'react';
import RRCard from './RRCard';
import Image1  from './img/20Jahre.jpg';
import Image2  from './img/2011_08_14_Eurocamp_130.jpg';
import Image3  from './img/Kontakt.jpg';
import Image4  from './img/Fahne.jpg';

export default class Submenu extends Component {
  render() {
    return (
      <div className='row'>
        <RRCard ImagePath={Image1} ButtonValue='Details »' ButtonLink='#Termine' Title='Termine' Content='Immer auf dem Laufenden bleiben...' />
        <RRCard ImagePath={Image2} ButtonValue='Details »' ButtonLink='#Programm' Title='Programm' Content='Unsere wöchentliche Arbeit...'/>
        <RRCard ImagePath={Image3} ButtonValue='Details »' ButtonLink='#Kontakt' Title='Kontakt' Content='Der Draht zu uns...'/>
        <RRCard ImagePath={Image4} ButtonValue='Details »' ButtonLink='#Treffpunkt' Title='Treffpunkte' Content='Der Weg zu uns...'/>
      </div>
      
    );
  }
}
