import React, { Component } from 'react';
import Actions, {  } from "./actions/Actions";
import Ages, {  } from "./ages/Ages";

export default class Programm extends Component {
  render() {
    return (
       <div>

         <h2 id='Programm'>Programm</h2>
         <p>Unser Programm richtet sich nach den vier Wachstumsbereichen: körperlich, geistig, geistlich und gesellschaftlich. Um den Kinder in den Wachstumsbereichen und ihren unterschiedlichen Entwicklungsstufen gerecht zu werden, sind sie bei unseren Treffen in Teams eingeteilt. Diese sind in drei Altersgruppen unterteilt und werden nach Geschlechter getrennt. Sie werden jeweils von einem männlichen bzw. einem weiblichen, erfahrenen Mitarbeiter mit entsprechenden Kenntnissen betreut.</p>
           <Ages/>
           <Actions/>
           

       </div>
    );
  }
}
