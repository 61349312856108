import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Image from "react-bootstrap/Image";

//import { Test } from './Newsfeed.styles';

class Newsfeed extends Component { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }




  render () {
    let description;

    if ((this.props.Id % 2 === 0)&& !(this.props.ImagePath === '')) {
      description = (
        <div className='row featurette'>
           <div className='col-md-7'>
             <h3 className='featurette-heading'>{this.props.Title}</h3>
             <p className=''>{this.props.Content}</p>
           </div>
           <div className='col-md-5'>
             <Image src={this.props.ImagePath} roundedCircle height='90%' width='90%'/>
           </div>
        </div>
      )
        
      
    } else if ( !(this.props.Id % 2 === 0)&& !(this.props.ImagePath === '')) {
      description = (
        <div className='row featurette'>

        <div className='col-md-5'>
          <Image src={this.props.ImagePath} roundedCircle height='90%' width='90%'/>
        </div>

        <div className='col-md-7'>
          <h3 className='featurette-heading'>{this.props.Title}</h3>
          <p className=''>
            {this.props.Content}

            
            </p>
        </div>
        
     </div>

      ) 
    } else {
      description = (
        <div className='row featurette' key={this.props.Id}>
          <h3 className='featurette-heading'>{this.props.Title}</h3>
          <p className=''>{this.props.Content}
          {this.props.Link && <Link to={this.props.Link}> --> hier!</Link> }
          {this.props.Download && <Link to={this.props.Download} target="_blank"> --> hier!</Link> } 
          </p>
        </div>


      )

    }


    return (
       <div> 
         
        {description} 
        <hr className='featurette-divider' />

       </div>
       

      );
}
};



export default Newsfeed;
