import React, { Component } from 'react';
import RrAccordion  from '../../../components/RrAccordion'

export default class Contact extends Component {
  constructor(props) {
    super(props);

  this.state = {

    data: [
      {
        Title: 'Stammleitung',
        Content: '',
        Vorname: 'Johannes',
        Nachname: 'März',
        Telefonnummer: '',
        Image: '/img/leiter/Hannes_M.png'
      },
      {
        Title: 'Entdecker',
        SubTitle:  '(4-6 Jahre)',
        Content: '',
        Vorname: 'Miriam',
        Nachname: 'Papentin',
        Telefonnummer: '',
        Image: ''
      },
      {
        Title: 'Forscher',
        SubTitle:  '(6-8 Jahre)',
        Content: '',
        Vorname: 'Christian',
        Nachname: 'Frank',
        Telefonnummer: '',
        Image: ''
      },
      {
        Title: 'Kundschafter',
        SubTitle:  '(9-11 Jahre)',
        Content: '',  
        Vorname: 'Andreas',
        Nachname: 'Behrens',
        Telefonnummer: '',
        Image: ''
      },
      {
        Title: 'Pfadfinder',
        SubTitle:  '(12-14 Jahre)',
        Content: '',  
        Vorname: 'Melanie',
        Nachname: 'Adetunji',
        Telefonnummer: '',
        Image: ''
      },
      {
        Title: 'Pfadranger',
        SubTitle:  '(15-17 Jahre)',
        Content: '',
        Vorname: 'Johannes',
        Nachname: 'März',
        Telefonnummer: '',
        Image: '/img/leiter/Hannes_M.png'  
      }
    ],
    hasError: false,
  };
}
  
  render() {
    return (
       <div>
         <h2 id="Kontakt">
           Kontakt
         </h2>

<RrAccordion WithImages='true' data={this.state.data}/>
         
       </div>

    );
  }
}
