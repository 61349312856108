import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';


export default class Impress extends Component {

constructor(props) {
  super(props)

  this.state = {
    showHide : props.showHide
  }
};


componentWillReceiveProps(){
  console.log("Test")
  this.setState ({showHide : this.props.showHide})
}

shouldComponentUpdate() {
  return true;
}



handleModalShowHide(){
  this.setState ({showHide : !this.state.showHide})
};

  render() {
    return (
      <Modal size="lg" show={this.state.showHide}>
        <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
          <Modal.Title>
            Impressum
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h3>Royal Rangers Stamm 142</h3>
              VIA Movement e.V
            <br/>        Ortsgemeinde Winnenden
            <br/>        HOPE Kirche 
            <br/>        Schorndorfer Straße 45
            <br/>        71364 Winnenden
            <br/>        
                      <h3> Die Royal Rangers sind ein Werk des:</h3>
                          Bund Freikirchlicher Pfingstgemeinden (BFP) KdöR
            <br/>        Industriestraße 6-8
            <br/>        64390 Erzhausen
            <br/>        
            <br/>        
            <br/>        <h3>Verantwortlich für den Inhalt ist:</h3>        
                          Johannes März
            <br/>        Eichendorffweg 12
            <br/>        71364 Winnenden
            <br/>        <h3>Webmaster:</h3>       
            <br/>        Patrick Papentin
            <br/>        
            <br/>        <h3>Haftungsshinweis:</h3>        
            <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keinerlei Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            </p>        <h3>Copyright:</h3>        
            <p>Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken und Texte zu beachten. Sollte sich auf den jeweiligen Seiten dennoch eine ungekennzeichnete, aber durch fremdes Copyright geschützte Grafik oder Text befinden, so konnte das Copyright vom Autor nicht festgestellt werden. Im Falle einer solchen unbeabsichtigten Copyrightverletzung wird der Autor das entsprechende Objekt nach Benachrichtigung aus seiner Publikation entfernen bzw. mit dem entsprechenden Copyright kenntlich machen.
            </p>        <h3>RR Emblem:</h3>        
            <p>Nur anerkannte Distrikte, Regionen und Stämme (keine Privatpersonen) dürfen das RR-Emblem auf ihrer Homepage führen. 
            </p>      

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.handleModalShowHide()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
