import React, { Component } from 'react';

export default class HTBARR extends Component {
  render() {
    return (
      <div>
        <h2>Ein Royal Ranger werden..... </h2>
        <p>Ihr Kind darf gerne einige Wochen ganz unverbindlich an unseren Treffs teilnehmen. Gefällt es Ihrem Kind bei uns, hat es die Möglichkeit bei uns Mitglied und ein richtiger „Royal Ranger" zu werden. Voraussetzung dafür ist, dass Ihr Kind dreimal an den wöchentlichen Treffs teilgenommen hat und ab der Kundschafterstufe noch eine kleine Aufnahmeprüfung abgelegt hat. In der Starterstufe gibt es noch keine Aufnahmeprüfung. </p>
        <h3> Die Bestandteile der Aufnahmeprüfung</h3>
        <ul>

          <li>Du warst mindestens drei mal bei einenem Strammtreff.</li>
          <li>Du bist mindestens neun Jahre alt.</li>
          <li>Erkläre die Bedeutung der Zacken des Royal Rangers Emblems.</li>
          <li>Erkläre das Motto der Royal Rangers.</li>
          <li>Sage die Goldene Regel auf.</li>
          <li>Lege das Versprechen ab und erkläre seine Bedeutung.</li>

        </ul>
        <p>
        Die Aufnahmeprüfung zum downloaden findest du hier.
        </p>
        <h3>Deine Ausrüstung</h3>
        <p>Die „Grundausrüstung“ besteht aus dem Fahrtenhemd, Halstuch mit Halstuchhalter, dem Logbuch und Liederbuch. Als Royal Ranger gibt es sehr viele interessante Dinge zu erforschen und zu lernen. Im sogenannten Logbuch wird das Erlernte dokumentiert und absolvierte Teilabschnitte mit Aufklebern bestätig; Dazu gibt es einen Aufnäher für die Kluft. Für weitere Ausrüstung für Camps u. ä. sprechen Sie am besten mit dem Teamleiter Ihres Kindes.</p>
        <h3>Unser Mitgliedsbeitrag</h3>
        <p>Um die Kosten der Arbeit zu decken, erheben wir einen jährlichen Mitgliedsbeitrag von 60, – € für das erste Kind, 45,-€ für das zweite Kind und je 25,-€ für jedes weitere Kind. Camps und Aktionen werden separat berechnet. Das Anmeldeformular und darin auch eine Liste für die notwendige Erstausrüstung finden Sie auf den nächsten Seiten. Möchte Ihr Kind eines Tages wieder austreten, ist dies jederzeit schriftlich möglich. Wir freuen uns schon auf die Zusammenarbeit mit Ihnen und Ihrem Kind. Für weitere Fragen stehen Ihnen die jeweiligen Teamleiter oder ich gerne zur Verfügung.</p>


      </div>
    );
  }
}


