import React, { Component } from 'react';

const  H1Style = {
  border: '0', 
  textAlign: 'center', 
  zIndex: 3,
  position: 'relative',
  top: '80px',
  margin: '-70px 0 20px',
  color: '#777'
};

export default class HeaderComponent extends Component {
  render() {
    return (
       <div>
             <h1 className="page-header" style={H1Style}>

      <img src="img/Logo.png" alt ="Logo"/>
        Royal-Rangers 142 
        <small>Winnenden</small></h1>
       </div>
    );
  }
}
