    import React, { useState } from 'react';
    import Carousel from 'react-bootstrap/Carousel';
    import Button from "react-bootstrap/Button";
    import SliderImage1 from "./img/038_22_JLTC_D_DN_008_min.jpg";
    import SliderImage2 from "./img/ANTC102.jpg";
    import SliderImage3 from "./img/Bundescamp.jpg";

    function RRCarosel() {
      const [index, setIndex] = useState(0);
      const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
      };
      
            const data = [
        {
         image: SliderImage1, 
         caption:"Abenteuer...",
         description:"Wir lieben das Abenteuer...",
         ButtonValue: "Werde ein Ranger...",
         ButtonLink: "#Ranger",
         alt: "Image 1",
         sliderstyle: {
          backgroundImage: `url(${SliderImage1})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          backgroundSize:'cover',
          height: '1000px'
          },
        shadowstyle: {
          textShadowColor: 'rgba(0, 0, 0, 0.6)',
          textShadowOffset: '1px, 2px',
          }
        },
        {
          image: SliderImage2, 
          caption:"Leidenschaft...",
          description:"",
          ButtonValue: "Unser Programm",
          ButtonLink: "#Programm",
          alt: "Image 2",
          sliderstyle: {
            backgroundImage: `url(${SliderImage2})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            height: '1000px'
            },
          shadowstyle: {
            textShadowColor: 'rgba(0, 0, 0, 0.6)',
            textShadowOffset: '1px, 2px',
          }
         },
         {
          image: SliderImage3, 
          caption:"Aktion...!",
          description:"Die Würze…",
          ButtonValue: "Unsere Aktionen",
          ButtonLink: "#Aktion",
          alt: "Image 3",
          sliderstyle: {
            backgroundImage: `url(${SliderImage3})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            height: '1000px'
            },
          shadowstyle: {
              textShadowColor: 'rgba(0, 0, 0, 0.6)',
              textShadowOffset: '1px, 2px',
           }
         } 
      ];
    
      return (
        <Carousel className="myCarousel" activeIndex={index} onSelect={handleSelect} interval={3000} pause={false} prevLabel="" nextLabel="">
           {data.map((slide, i) => {
            return (
              <Carousel.Item style={slide.sliderstyle} key={i} >        
              
                <Carousel.Caption>
                  <h1 style={slide.shadowstyle}>{slide.caption}</h1>
                  <p style={slide.shadowstyle}>{slide.description}</p>
                  <Button href={slide.ButtonLink}>{slide.ButtonValue}</Button> 
                </Carousel.Caption>
            </Carousel.Item>
            )
          })}
        </Carousel>
      )
    }
    export default RRCarosel;

