import React, { Component } from 'react';
import Newsfeed, {  } from "./Newsfeed";

export default class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

   data = [
    {
      Title: "Wir starten wieder mit Royal-Rangers Teamtreffs",
      Content: 'Ab dem 13.09 Starten wieder unsere wöchentlichen Teamtreffs in den jeweiligen Teams. Am 20.9 findet der Stufenwechsel für die betroffen Rangers statt.',
      Teaser: '',
      Image:''
      
    },
    {
      Title: "Entdecker, Wir starten mit einer neue Alterstufe ",
      Content: 'Freitags, von 15:30 findet die neue Altersstufe (4-6 jährige) statt',
      Teaser: '',
      Image:''
    }
   ];
      
  render() {
    return (
       <div>
         <hr className='featurette-divider' />
         <h2>Aktuelles</h2>

          {this.data.map((News, i) => {
            return (
            
              <Newsfeed Title={News.Title} ImagePath={News.Image} Content={News.Content} Teaser={News.Teaser} Id={i} key={i} Link={News.Link} Download={News.Download} />
            
            )
          })
          }


         
       </div>
    );
  }
}
