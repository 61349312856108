import React, { Component } from 'react';

import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

export default class RRCard extends Component {

  render() {
    return (
<div className='col-lg-3'>
    <Image src={this.props.ImagePath} roundedCircle height='140px' width='140px'/>
 <h2> {this.props.Title}</h2>
 <p> {this.props.Content}</p>
 <Button href={this.props.ButtonLink}>{this.props.ButtonValue}</Button> 
</div>
    );
  }
}
