import React, { Component } from 'react';
import RrAccordion  from '../../../../components/RrAccordion'
export default class Ages extends Component {
  constructor(props) {
    super(props);

    this.state = {

      data: [
        {
          Title: 'Entdecker ',
          SubTitle:  'ab 4 Jahre',
          Content: 'Wir sind die Entdecker! Und wir lieben es mit unserem Tatendrang die Natur zu erobern. Bei uns ist immer etwas los.          Wir können schon sehr viel! Wir experimentieren, bauen, beobachten die Natur, spielen und helfen mit. Stolz tragen wir unser Halstuch mit dem Abzeichen.',
        },
        {
          Title: 'Forscher',
          SubTitle: '(6-8 Jahre)',
          Content: 'Wir Forscher sind eine echte Rasselbande. Toben, graben, fangen, bauen! Wir sind dabei, wenn das Abenteuer ruft. Schlechtes Wetter kann uns dabei nicht erschrecken. Aufmerksam entdecken wir die Tier- und Pflanzenwelt. In sechs Stufen erforschen wir je ein Tier mit seinem Umfeld – vom Salamander bis zum Adler. Zur Belohnung erhalten wir dafür Abzeichen für unser Fahrtenhemd. Durch die kleineren und größeren Herausforderungen, die wir Forscher erleben, lernen wir auch aufeinander zu achten. Zusammen mit unseren Freunden wachsen wir zu einem Team heran.', 
        },
        {
          Title: 'Kundschafter',
          SubTitle: '(9-11 Jahre)',
          Content: 'Als Kundschafter erleben wir die tolle Atmosphäre von Camps. Wir übernachten in unserem selbst aufgebauten Schlafzelt und kochen unser Essen über offenem Feuer in der eigenen Campküche. Angefangen mit der Beobachterstufe erfahren wir bis zur Meisterstufe Einiges über Pfadfindertechniken, Natur und Erste Hilfe. Ganz praktisch lernen wir Knoten und üben uns im Umgang mit Werkzeug, Karte und Kompass. Als Kundschafter ist es uns wichtig Selbständigkeit zu erlernen. Unser Leiter ist zwar immer dabei, aber einige Aufgaben, wie den Campaufbau, Holz hacken und Kochen, meistern wir jetzt selbst.',  
        },
        {
          Title: 'Pfadfinder',
          SubTitle: '(12-14 Jahre)',
          Content: 'Camps zu bewältigen sind wir gewöhnt. Jetzt brechen wir auf, um bei Hajks und Fahrten neu herausgefordert zu werden. Für unser großes Ziel, die Goldlilie, können wir eigene Schwerpunkte jetzt selbst wählen. Die Themen erstrecken sich über Bereiche wie z.B. Sport, Technik und Politik. Als Pfadfinder lernen wir Verantwortung zu übernehmen. Einzelne Programmpunkte werden nun von uns selbst geplant und geleitet. Ab 14 Jahren eröffnet sich für uns die Möglichkeit, das Gelernte in einer Ausbildung zum Juniorleiter umzusetzen.',  
        },
        {
          Title: 'Pfadranger',
          SubTitle: '(15-17 Jahre)',
          Content: 'Als Pfadranger erleben wir eine echt coole Zeit. Eigene Aktionen wie Hajks und Camps werden genauso zu herausfordernden Projekten, wie der Dienst an unseren Mitmenschen und der Gesellschaft. Durch unser Navigator-Abzeichen sind wir herausgefordert, anderen Menschen unter die Arme zu greifen. Vor Ort in unserem Stamm oder in fremden Ländern wollen wir mit RIDEs eine echte Unterstützung sein. Als Juniorleiter übernehmen wir selbst Verantwortung und lernen andere zu leiten. Das ist ein wichtiger Teil unserer Pfadrangerzeit, bei dem wir zeigen, was in uns steckt. Hier werden wir als Mensch ernst genommen.',  
        },
        {
          Title: 'Leiter',
          SubTitle: '(ab 18 Jahre)',
          Content: 'Leiter sein bedeutet für uns nicht, Leidender zu sein. Auch als Erwachsene bedeutet Ranger sein, viel Spaß zu haben und tolle Aktionen zu erleben. Basis für unsere Mitarbeit ist eine viertägige Ausbildung, die durch weitere Zusatzausbildungen und Seminare ergänzt werden kann. Das pfadfinderische Prinzip ist, den Kindern und Jugendlichen etwas zuzutrauen und ihnen anhand praktischer Erlebnisse etwas beizubringen. So wollen wir als Leiter nicht in Theorie, sondern in der Natur und praxisnah jeden einzelnen fördern und begleiten. Als Leiter haben wir eine Vorbildfunktion, die wir bei allem Spaß nicht vergessen, sondern sehr ernst nehmen. Wir wollen durch unser Vorbild den Kindern und Jugendlichen Werte vermitteln. Insbesondere zählt dazu die Goldene Regel aus Matthäus 7,12 „Alles, was ihr für euch von den Menschen erwartet, das tut ihnen auch“',  
        }
      ],
      hasError: false,
    };
  }
  
  render() {
    return (
       <div>
         <h3> Altersstufen  </h3>

         <RrAccordion WithImages='false' data={this.state.data}/>
       </div>
    );
  }
}
