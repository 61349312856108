    import React, { Component } from 'react';
    import Places from './Places';
    
    
    export default class Dates extends Component {
      render() {
        return (
           <div>
             <h2 id='Termine'>Termine </h2>
             <div className='row'>
             <h3 id='Treffpunkt'>Stammtreffs</h3>
             <div className='col-lg-6'>
               <ul key='udate1'>
                 <li key='date1'>Jeden Freitag zwischen 15:30 und 17:00 Uhr treffen sich die Entdecker (4-5 Jährige).</li>
                 <li key='date2'>Jeden Freitag zwischen 18:00 und 19:30 Uhr treffen sich die Forscher (6-8 Jährige).</li>
                 <li key='date3'>Jeden Freitag zwischen 18:00 und 20:00 Uhr treffen sich die Kundschafter (9-11 Jährige) Pfadfinder (12-14 Jährige) und Pfadranger(15-17 Jährige)</li>
               </ul>
             Treffpunkt ist zwischen Ostern und den Herbstferien auf unserer Rangerwiese in Hertmannsweiler. Die restliche Zeit sind wir auf dem Gelände der Hope Kirche Winnenden in der Schorndorfer Straße 43 - 45
             </div>
             <div className='col-lg-6'><Places/></div>
             </div>
             
             <h3>Save the Date </h3>
             <p>
             <ul>
              <li>Pfingsten 2025 findet für alle Pfadranger und junge Leiter bis 21 Jahre wieder das Unlimited statt.</li>
              <li>Erste Sommerferienwoche Sommercamp mit den Rangerstammposten aus Murrhard,Fellbach und Schorndorf-Weiler</li>
             </ul>

             </p>
           </div>
        );
      }
    }
    