import React from 'react';
import  Mainpage from './pages/mainpage/mainpage';
import  HeaderComponent from './components/Header/Header';
import  Menu  from './components/menu/Menu';

import ScrollToTop from './components/scroll'


import {
  BrowserRouter as Router,
  Switch,
  Route,
 // Link,
} from "react-router-dom";


import  'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/carousel.css';
import './assets/css/RR_Color.css';


export class App extends React.Component {
  render () {
    return (
    <div className="App RRA_grau_bg">
      
      <HeaderComponent/>
      
      <Router>
        <ScrollToTop />
        <Switch>

          
           
          <Route path="/">
            <Mainpage/>
          </Route>
        </Switch>
        <Menu/>
      </Router>
      
      
      
      
      
    </div>
);
}
}