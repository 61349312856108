    import React, { Component } from 'react';
    
    export default class Actions extends Component {
      render() {
        return (
           <div>
             <h3>Aktivitäten</h3>
             <h4>Stamm- und Teamtreff</h4>
             <p>Royal Rangers treffen sich in der Regel wöchentlich in ihrem Stammposten. Singen und Spielen gehört hier genauso zum Programm wie das Erlernen von Pfadfindertechniken. Dazu zählen Knoten, Feuermachen, der Umgang mit Werkzeug, Erste Hilfe aber auch das Erlernen sozialer Kompetenzen und das Auseinandersetzten mit dem christlichen Glauben. 
             Wichtigstes Element der Royal-Rangers-Arbeit ist der Teamtreff. In kleinen Teams aus sechs bis zehn Teilnehmern gleichen Alters und Geschlechts werden die Kinder entsprechend ihren Fähigkeiten gefördert </p>
             
             <h4>Camp</h4>
             <p>
Das jährliche stattfindende Pfingst-, Sommer- oder Wintercamp ist meist auch das Highlight des Rangerjahres. Während eines Camps haben die Teams Zeit, sich eingehend mit den Pfadfindertechniken auseinander zu setzten. Jetzt findet das Gelernte Anwendung. Auf dem eigenen Teambereich (der Platz, auf dem jedes Team sein Zuhause hat), werden neben dem Schlafzelt auch ein Feuertisch und ein Essplatz gebaut. Diese Bauten bestehen dabei ausschließlich aus Holz und werden durch stabile Bünde zusammengehalten.</p>
             <h4>Hajk</h4>
             <p>Hajk ist ein aus dem Nordischen stammender Begriff, der in vielen Pfadfinderbewegungen und auch ganz allgemein Verwendung findet. Generell verbinden Royal Rangers damit, unterwegs zu sein: z.B. zu Fuß, mit dem Kanu oder Fahrrad. Draußen in der Natur sind Royal Rangers zu Hause, erleben Abenteuer und bestehen Herausforderungen. Bei einem Hajk packen die Rangers alles Nötige in den Rucksack und ziehen los.</p>
           </div>
        );
      }
    }
    